import {
  HeaderData,
  HeaderNavItem,
} from "@/components/Header/header_component.d";
import { Header } from "@horizon/components";
import { Authentication } from "@/libs";
import { PATHS } from "@/services/libs";
import { useRouter } from "next/router";
import { logoImageUrl } from "@/libs/utils";

export const LegacyHeaderComponent = ({
  headerData,
}: {
  headerData: HeaderData;
}) => {
  const userBtn = headerData.primaryLinks?.slice(-1)[0];
  const primaryLinks = headerData.primaryLinks?.slice(0, -1);
  const pathWithSearch = useRouter().asPath;
  const path = pathWithSearch.split("?")[0];

  return (
    <Header
      primaryLinks={flattenNavigationColumnsForLegacyHeader(primaryLinks || [])}
      primaryActive={PrimaryActive(path, primaryLinks)}
      secondaryActive={path}
      utilityLinks={null}
      userBtn={userBtn}
      cta={loggedOutCTA(pathWithSearch)}
      logoImage={{
        src: logoImageUrl,
      }}
    />
  );
};

const flattenNavigationColumnsForLegacyHeader = (
  primaryLinks: Array<HeaderNavItem>
): Array<HeaderNavItem> => {
  return primaryLinks.map((link) => {
    if (link.navItems) {
      return {
        ...link,
        navItems: flattenNavigationColumn(link.navItems),
      };
    }
    return link;
  });
};

const flattenNavigationColumn = (
  navColumns: Array<HeaderNavItem>
): Array<HeaderNavItem> => {
  return navColumns.reduce((acc, column) => {
    if (column.navItems) {
      return [...acc, ...flattenNavigationColumn(column.navItems)];
    }
    return [...acc, column];
  }, [] as Array<HeaderNavItem>);
};

const PrimaryActive = (
  currentPath: string,
  primaryLinks: Array<HeaderNavItem> | undefined
) => {
  if (currentPath === PATHS.root) {
    return PATHS.root;
  }

  if (currentPath === PATHS.search) {
    return PATHS.search;
  }
  const parentPath = currentPath.split("/")[1];
  const parentLink = primaryLinks?.find((link) =>
    link.href?.includes(parentPath)
  );
  return parentLink?.href || "/";
};

const loggedOutCTA = (currentPath: string) => {
  return {
    label: "Sign In",
    href: Authentication.signinHrefPath(currentPath),
  };
};
