import { HeaderData } from "@/components/Header/header_component.d";
import { ContentfulProvider } from "@/services/providers";
import { toFooterData, toHeaderData } from "./navigation_mapper";
import { AuthenticatedUser } from "@/libs";

const retrieveNavigation = async (
  user: AuthenticatedUser | undefined
): Promise<HeaderData | null> => {
  let navigation;
  if (user) {
    navigation = await ContentfulProvider.getNavigationContainerById(
      process.env.NAVIGATION_HEADER_ID
    );
  }

  if (!user && !navigation) {
    navigation = await ContentfulProvider.getNavigationContainerById(
      process.env.LOGGED_OUT_NAVIGATION_ID
    );
  }
  if (navigation) {
    return toHeaderData(navigation);
  }
  return null;
};

const retrieveFooter = async (): Promise<any> => {
  const footer = await ContentfulProvider.getNavigationContainerById(
    process.env.FOOTER_NAVIGATION_ID
  );
  if (footer) {
    return toFooterData(footer);
  }
  return null;
};

export const NavigationService = { retrieveNavigation, retrieveFooter };
