import {
  HeaderData,
  HeaderNavItem,
} from "@/components/Header/header_component.d";
import { PATHS } from "@/services/libs";
import { UserContext } from "@auth0/nextjs-auth0/client";
import { AppHeader } from "@horizon/components";
import { useRouter } from "next/router";

export const AppHeaderComponent = ({
  user,
  headerData: { primaryLinks },
}: {
  user: UserContext;
  headerData: HeaderData;
}) => {
  const pathWithSearch = useRouter().asPath;
  const path = pathWithSearch.split("?")[0];

  return (
    <AppHeader
      account={getAccountCTAS(user)}
      actions={getActions(user, path)}
      homeLink={PATHS.home}
      pathName={path}
      navItems={setParentActiveWhenChildIsActive(
        setActiveForPath(primaryLinks || [], path)
      )}
    />
  );
};

const getAccountCTAS = (user: UserContext) => {
  if (user?.user) {
    return {
      avatar: {
        name: user.user.name,
        image: user.user.picture && { src: user.user.picture },
      },
      menuItems: [
        {
          label: "My collections",
          href: "/account/collections",
          icon: "Heart",
        },
        {
          label: "Account details",
          href: "/account",
          icon: "File",
        },
        {
          label: "Log out",
          href: "/api/auth/logout",
          icon: "Logout",
        },
      ],
    };
  }
};

const getActions = (user: UserContext, currentPath: string) => {
  if (user?.user) {
    return [
      {
        accessibilityLabel: "Search through the site",
        href: PATHS.search,
        active: currentPath === PATHS.search,
        icon: "Search",
        label: "Search",
        onClick: () => console.log("Search clicked"),
      },
    ];
  }
};

const setActiveForPath = (
  primaryLinks: Array<HeaderNavItem>,
  currentPath: string
): Array<HeaderNavItem> => {
  return primaryLinks.map((link) => {
    if (link.href && currentPath.includes(link.href)) {
      link = { ...link, active: true };
    }
    if (link.navItems !== (null || undefined) && link.navItems.length > 0) {
      link = {
        ...link,
        navItems: setActiveForPath(link.navItems, currentPath),
      };
    }
    return link;
  });
};

const setParentActiveWhenChildIsActive = (
  primaryLinks: Array<HeaderNavItem>
) => {
  return primaryLinks.map((link) => {
    if (link.navItems && isAtLeastOneNavItemActive(link.navItems)) {
      return { ...link, active: true };
    }
    return link;
  });
};

const isAtLeastOneNavItemActive = (navItems: Array<HeaderNavItem>): boolean => {
  return navItems.find((navItem) => isNavItemActive(navItem)) !== undefined;
};

const isNavItemActive = (navItem: HeaderNavItem): boolean => {
  if (navItem.navItems) {
    return (
      navItem.navItems.find((navItem) => isNavItemActive(navItem)) !== undefined
    );
  }
  return navItem.active || false;
};
