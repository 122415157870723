import { HeaderData } from "@/components/Header/header_component.d";
import { AppHeaderComponent } from "@/components/Header/AppHeaderComponent";
import { useUser } from "@auth0/nextjs-auth0/client";
import { LegacyHeaderComponent } from "@/components/Header/LegacyHeaderComponent";

export const HeaderComponent = (headerItems: HeaderData) => {
  const user = useUser();

  if (user?.user) {
    return <AppHeaderComponent user={user} headerData={headerItems} />;
  } else {
    return <LegacyHeaderComponent headerData={headerItems} />;
  }
};
