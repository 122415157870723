import { RichTextBlockData, TextComponentData } from "@/renderers";
import { Column, Grid, HeroTitle, PageTitle, Text } from "@horizon/components";
import { InLineLink } from "./InLineLink";

const wrapInMarks = (content: string, marks: string[]) => {
  if (marks.includes("bold")) {
    return (
      <b>
        {wrapInMarks(
          content,
          marks.filter((mark) => mark !== "bold")
        )}
      </b>
    );
  }
  if (marks.includes("italic")) {
    return (
      <i>
        {wrapInMarks(
          content,
          marks.filter((mark) => mark !== "italic")
        )}
      </i>
    );
  }
  if (marks.includes("mark")) {
    return (
      <mark>
        {wrapInMarks(
          content,
          marks.filter((mark) => mark !== "mark")
        )}
      </mark>
    );
  }

  return content;
};

export const RichTextComponent = (
  { as, content, element, marks, size, textDecoration }: TextComponentData,
  key: number
) => {
  switch (element) {
    case "pageTitle":
      return (
        <PageTitle key={key} as={as} my="large" size={size}>
          {content}
        </PageTitle>
      );
    case "heroTitle":
      return (
        <HeroTitle key={key} as={as} my="large" size={size}>
          {content}
        </HeroTitle>
      );
    case "inline":
      let contents = Array.isArray(content) ? content : [content];
      return (
        <Text size={size} as={as} mb="large" textDecoration={textDecoration}>
          {contents.map((con, index) => {
            if (typeof con === "string") {
              return con;
            }
            if (con?.element === "text") {
              return (
                typeof con.content === "string" &&
                wrapInMarks(con.content, con.marks)
              );
            }
            if (con?.element === "hyperlink") {
              return (
                <InLineLink href={con.href} key={index}>
                  {typeof con.content === "string" &&
                    wrapInMarks(con.content, con.marks)}
                </InLineLink>
              );
            }
            return null;
          })}
        </Text>
      );
    case "text":
      return (
        <Text size={size} as={as} mb="large" textDecoration={textDecoration}>
          {typeof content === "string" && wrapInMarks(content, marks)}
        </Text>
      );
    case "lineBreak":
      return <hr key={key}></hr>;
    case "listItem":
      return (
        <li>
          {Array.isArray(content) &&
            content.map((elements: TextComponentData, index: number) => {
              Array.isArray(elements) &&
                elements.map((element: TextComponentData, key: number) => {
                  return (
                    <RichTextComponent {...element} key={`${index}-${key}`} />
                  );
                });

              return (
                <RichTextComponent {...elements} key={`${index}-${key}`} />
              );
            })}
        </li>
      );
    case "unorderdList":
      return (
        <ul>
          {Array.isArray(content) &&
            content.map((listItem: TextComponentData, index: number) => {
              return <RichTextComponent {...listItem} key={index} />;
            })}
        </ul>
      );
    case "orderedList":
      return (
        <ol>
          {Array.isArray(content) &&
            content.map((listItem: TextComponentData, index: number) => {
              return <RichTextComponent {...listItem} key={index} />;
            })}
        </ol>
      );
  }
  return null;
};

export const RichTextBlockComponent = (
  { content, isFullWidth }: RichTextBlockData,
  key: number
) => {
  const widthProps = isFullWidth ? 12 : [12, 8];
  return (
    <Grid key={key}>
      <Column width={widthProps}>
        {Array.isArray(content) &&
          content.map((textBlock: TextComponentData, index: number) => {
            return <RichTextComponent {...textBlock} key={index} />;
          })}
      </Column>
    </Grid>
  );
};
