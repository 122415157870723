const NAVIGATION_ITEM_FRAGMENT = `fragment NavigationItemFragment on NavigationItem {
  navigationItemLabel
  navigationItemDescription
  url
  requiredUserAction
  secondaryNavigationColumnsCollection {
      items {
          ...NavigationColumnFragment
      }
  }
  media {
      title
      description
      contentType
      fileName
      size
      url
      width
      height
  }  
}`;

const NAVIGATION_COLUMN_FRAGMENT = `fragment NavigationColumnFragment on NavigationColumn {
  navigationColumnType
  navigationItemCollection {
    items {
      media {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
      }
      navigationItemLabel
      navigationItemDescription
      url
      requiredUserAction
    }
  }
}`;

export const NAVIGATION_CONTAINER_BY_ID = `query($preview: Boolean, $navigationId: String!, $locale: String)
{
  navigationContainer(id: $navigationId, preview: $preview, locale: $locale) {
    navigationItemsCollection {
      items {
        ...NavigationItemFragment
      }
    }
  }
}
${NAVIGATION_ITEM_FRAGMENT}
${NAVIGATION_COLUMN_FRAGMENT}
`;

// query NavigationContainer {
//   navigationContainer(id: "3Gpq7dcKnlAJ9vaJYQxgJD") {
//       instanceName
//       navigationItemsCollection {
//           items {
//               instanceName
//               navigationItemLabel
//               url
//               navigationItemDescription
//               requiredUserAction
//               secondaryNavigationColumnsCollection {
//                   items {
//                       navigationColumnType
//                   }
//               }
//               media {
//                   title
//                   description
//                   contentType
//                   fileName
//                   size
//                   url
//                   width
//                   height
//               }
//           }
//       }
//   }
// }
