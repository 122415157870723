import { Footer as HorizonFooter } from "@horizon/components";
import {
  FooterData,
  FooterNavComponent,
  FooterNavItem,
} from "@/components/Footer/FooterComponent.d";

export const FooterComponent = (footerData: FooterData | null) => {
  if (!footerData) return null;
  const { columns, social, secondaryLinks } = footerData;

  return (
    <HorizonFooter
      columns={flattenNavigationColumns(columns || [])}
      social={social}
      secondaryLinks={secondaryLinks}
    />
  );
};

const flattenNavigationColumns = (
  columns: Array<FooterNavComponent>
): Array<FooterNavComponent> => {
  return columns.map((column) => {
    const component = column.component;
    if (component.navItems) {
      return {
        ...column,
        component: {
          ...component,
          navItems: flattenNavigationColumn(component.navItems),
        },
      };
    }
    return column;
  });
};

const flattenNavigationColumn = (
  navItems: Array<
    {
      navItems?: Array<FooterNavItem>;
    } & FooterNavItem
  >
): Array<FooterNavItem> => {
  return navItems.reduce((acc, item) => {
    if (item.navItems) {
      return [...acc, ...flattenNavigationColumn(item.navItems)];
    }
    return [...acc, item];
  }, [] as Array<FooterNavItem>);
};
