import { Link } from "@horizon/components";
import { ReactNode } from "react";

export const InLineLink = ({
  href,
  key,
  children,
  style = { fontSize: "18px" },
}: {
  href: string | undefined;
  key: string | number;
  children: ReactNode;
  style?: object;
}) => {
  return (
    <Link href={href} key={key} style={style}>
      {children}
    </Link>
  );
};
